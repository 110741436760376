









import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

@Component({})
export default class Prompt extends Vue {
  @Prop() private readonly data!: any;
  @Prop() private readonly confirm!: boolean;

  public created() {}

  @Watch('confirm')
  handleConfirm() {
    this.$modal.confirm();
  }
}
